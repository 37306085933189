import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Layout from '../layout/Stage'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'indexParent',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/index/Index'),
        meta: { title: '查询记录', icon: 'el-icon-house' }
      }
    ]
  },

  {
    path: '/index',
    component: Layout,
    name: 'indexexpression',
    children: [
      {
        path: '/expressiont',
        name: 'expressiont',
        component: () => import('@/views/index/Index'),
        meta: {
          title: '生成探针-表情',
          icon: 'el-icon-magic-stick',
          dialogType: 'expressiont'
        }
      }
    ]
  },
  {
    path: '/indexLink',
    component: Layout,
    name: 'indexlink',
    children: [
      {
        path: '/link',
        name: 'link',
        component: () => import('@/views/index/Index'),
        meta: {
          title: '生成探针-链接',
          icon: 'el-icon-link',
          dialogType: 'link'
        }
      }
    ]
  },

  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/AuthLogin.vue')
  },

  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
