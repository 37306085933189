import request from '@/plugins/axios'

export function authLogin(origin, auth) {
  return request({
    url: `/restLogin?username=${origin}&auth=${auth}`,
    method: 'post'
  })
}

export function logOut() {
  return request({
    url: '/logout',
    method: 'get'
  })
}

export function loginUserInfo() {
  return request({
    url: '/probeSystem/info',
    method: 'get'
  })
}

export function pageApi(page, limit, data) {
  return request({
    url: `/probeSystem/${page}/${limit}`,
    method: 'post',
    data
  })
}

export function addApi(data) {
  return request({
    url: `/probeSystem/add`,
    method: 'post',
    data
  })
}

export function delApi(id) {
  return request({
    url: `/probeSystem/${id}`,
    method: 'delete'
  })
}
