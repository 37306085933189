import axios from 'axios'
import { Message } from 'element-ui'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
import Router from '../router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// response interceptor
service.interceptors.response.use(
  response => {
    switch (response.status) {
      case 200:
        return response.data.data
      case 201:
        Message.success('创建成功')
        return response.data.data
      default:
        Message.error(response.status)
        return response.data.data
    }
  },
  error => {
    let { response } = error
    switch (response.status) {
      case 401:
        Message.warning('登录状态失效,请重新登录')
        const origin = window.localStorage.getItem('tz_origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          Router.push({ path: '/401' })
        } else {
          window.location.href = origin || process.env.VUE_APP_INDEX
        }
        return Promise.reject(new Error(response.statusText))

      default:
        Message.error(response.statusText)
        return Promise.reject(new Error(response.statusText))
    }
  }
)

export default service
